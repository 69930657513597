import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs';

const Footer = ({ footerContent }) => {
  if (!footerContent) return null
  const footer = footerContent.data
  return (
  <footer class="footer">
    <div class="content has-text-centered">
      <div class="title">
        {footer.title.text}
      </div>
      <div class="subtitle">
        <RichText render={footer.message.raw} />
      </div>
      <div class="subtitle">
        <RichText render={footer.email.raw} />
      </div>
    </div>
  </footer>)
}


export const query = graphql`
  fragment FooterQuery on PrismicFooter {
    data {
      title {
        text
      }
      message {
        raw
      }
      email {
        raw
      }
    }
  }
`

export default Footer
