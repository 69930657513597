import React from 'react'
import Footer from './Footer'

const Layout = ({ isHomepage, children, footerContent }) => (
  <>
      {children}
    <Footer footerContent={footerContent}/>
  </>
)

export default Layout
